var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: `vs-scheduler-event ${_vm.isInteracting && 'interacting'}`,
    style: _vm.eventStyles,
    on: {
      "mousedown": _vm.startMoving
    }
  }, [_c('div', {
    staticClass: "event-background"
  }), _c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "name"
  }, [_vm._v(" " + _vm._s(_vm.name) + " "), _c('div', {
    staticClass: "color-indicator"
  }, [_vm._v(" " + _vm._s(_vm.category) + " ")]), _vm.overwritten ? [_vm._v(" ZMIENIONO ")] : _vm._e()], 2), _c('div', {
    staticClass: "date"
  }, [_vm._v(" " + _vm._s(_vm.days) + " dni (" + _vm._s(_vm.formatDate.training(_vm.dateFrom)) + " - " + _vm._s(_vm.formatDate.training(_vm.dateTo)) + ") ")])]), !_vm.disableInteraction ? _c('div', {
    staticClass: "resize-indicator",
    on: {
      "mousedown": function ($event) {
        $event.stopPropagation();
        return _vm.startResizing.apply(null, arguments);
      }
    }
  }, [_vm._v(" || ")]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }