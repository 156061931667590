<template>
  <div
    class="vs-scheduler-event-reel"
    :style="{
      '--event-rows': eventRows
    }"
  >
    <div class="event-source">
      <div class="event-source-content">
        <slot name="event-source">
          {{ label }} {{ eventRows }}
        </slot>

        <vs-button
          class="add-button"
          vs-variant="link"
          @click="$emit('add')"
        >
          <feather-icon
            icon="PlusCircleIcon"
            size="18"
          />
        </vs-button>
      </div>
    </div>

    <vs-scheduler-event
      v-for="(event, idx) in eventsWithRows"
      :key="`event-${idx}`"
      :name="event.name"
      :date-from="event.date_from"
      :date-to="event.date_to"
      :days="event.days"
      :overwritten="event.overwritten || false"
      :offset="getDateOffsetColumn(event.date_from)"
      :span="getDateWidthColumn(event.date_from, event.date_to)"
      :row="event.row"
      :color="event.color"
      :category="event.category"
      @resize="(width) => $emit('resize', width, event)"
      @move="(offset) => $emit('move', offset, event)"
      @interact="({type, initial}) => $emit('interact', event, type, initial)"
      @click="() => $emit('event:click', event)"
      :disableInteraction="daysPerColumn !== 1"
    />

    <div class="dimmer"></div>

  </div>
</template>

<script>
import { computed } from 'vue';
import VsSchedulerEvent from './VsSchedulerEvent.vue';
import VsButton from '../vs-button/VsButton.vue';

export default {
  components: { VsSchedulerEvent, VsButton },
  name: 'VsSchedulerEventReel',
  emits: ['resize', 'move', 'interact', 'add', 'event:click'],
  props: {
    events: {
      type: Array,
      default: () => [],
    },
    scheduleStartDate: {
      type: Date,
      default: () => new Date(),
    },
    label: {
      type: String,
      default: '-',
    },
    daysPerColumn: {
      type: Number,
      default: 1,
    },
  },
  setup(props) {
    const getDateOffsetColumn = (date = null) => {
      const d = new Date(date);
      const diff = d.getTime() - props.scheduleStartDate.getTime();
      const offset = Math.ceil(diff / ((1000 * 3600 * 24) / props.daysPerColumn));
      return (offset / props.daysPerColumn);
    };

    const getDateWidthColumn = (start = null, end = null) => {
      const d = new Date(start);
      const e = new Date(end);
      const diff = e.getTime() - d.getTime();
      const offset = Math.ceil(diff / ((1000 * 3600 * 24) / props.daysPerColumn));
      return offset / props.daysPerColumn + (props.daysPerColumn === 1 ? 1 : 0);
    };

    const eventsWithRows = computed(() => {
      const rows = {};

      const markOccupiedColumns = (event) => {
        const arr = Array.from({
          length: getDateWidthColumn(event.date_from, event.date_to),
        }, (_, i) => i + getDateOffsetColumn(event.date_from));

        if (Object.keys(rows).length === 0) {
          rows[0] = arr;
          return 0;
        }

        const rowsNumbers = Object.keys(rows);

        let freeRow;
        rowsNumbers.some((idx) => {
          const overlaps = arr.some((date) => rows[idx].includes(date));
          if (overlaps) return false;
          freeRow = Number(idx);
          return true;
        });

        if (!freeRow && freeRow !== 0) {
          freeRow = rowsNumbers.length;
        }

        rows[freeRow] = [...rows[freeRow] ?? [], ...arr];

        return freeRow;
      };

      const rowed = [...props.events]
        .sort((a, b) => {
          const dateA = new Date(a.date_from);
          const dateB = new Date(b.date_from);
          return dateA.getTime() - dateB.getTime();
        })
        .map((event) => {
          const row = markOccupiedColumns(event);

          return {
            ...event,
            row,
          };
        });

      return rowed;
    });

    const eventRows = computed(() => Math
      .max(...eventsWithRows.value.map(({ row }) => row)));

    return {
      getDateOffsetColumn,
      getDateWidthColumn,
      eventRows,
      eventsWithRows,
    };
  },
};
</script>

<style lang="sass" scoped>
.event-source
  background: linear-gradient(90deg, var(--vs-scheduler-bg) 90%, rgba(255, 255, 255, 0) 100%)
  width: 300px
  height: 100%
  display: flex
  align-items: flex-start
  z-index: 5
  position: relative
  padding: 0 10px

  &-content
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between

.vs-scheduler-event-reel
  --event-rows: 1
  --reel-dimmer-width: 20px

  min-height: calc(40px * (var(--event-rows) + 1))

  grid-column: 1 / calc(var(--cols) + 2)
  position: relative
  transition: 0.2s all ease-in-out
  width: calc(100% + var(--reel-dimmer-width))
  overflow: hidden

  &:after
    content: ''
    display: block
    width: calc(100% - var(--reel-dimmer-width))
    height: 1px
    background: var(--vs-scheduler-border)
    position: absolute
    bottom: 0
    z-index: 6

  .dimmer
    width: calc(var(--reel-dimmer-width) + 5px)
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, var(--vs-card-bg) 50%)
    height: 100%
    background: red
    position: absolute
    right: 0
    top: 0
    z-index: 6

  &:hover
    .add-button
      opacity: 1

  .add-button
    opacity: 0
    transition: 0.2s all ease-in-out
</style>
