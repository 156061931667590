var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "vs-scheduler-event-reel",
    style: {
      '--event-rows': _vm.eventRows
    }
  }, [_c('div', {
    staticClass: "event-source"
  }, [_c('div', {
    staticClass: "event-source-content"
  }, [_vm._t("event-source", function () {
    return [_vm._v(" " + _vm._s(_vm.label) + " " + _vm._s(_vm.eventRows) + " ")];
  }), _c('vs-button', {
    staticClass: "add-button",
    attrs: {
      "vs-variant": "link"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('add');
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PlusCircleIcon",
      "size": "18"
    }
  })], 1)], 2)]), _vm._l(_vm.eventsWithRows, function (event, idx) {
    return _c('vs-scheduler-event', {
      key: `event-${idx}`,
      attrs: {
        "name": event.name,
        "date-from": event.date_from,
        "date-to": event.date_to,
        "days": event.days,
        "overwritten": event.overwritten || false,
        "offset": _vm.getDateOffsetColumn(event.date_from),
        "span": _vm.getDateWidthColumn(event.date_from, event.date_to),
        "row": event.row,
        "color": event.color,
        "category": event.category,
        "disableInteraction": _vm.daysPerColumn !== 1
      },
      on: {
        "resize": function (width) {
          return _vm.$emit('resize', width, event);
        },
        "move": function (offset) {
          return _vm.$emit('move', offset, event);
        },
        "interact": function (_ref) {
          var type = _ref.type,
            initial = _ref.initial;
          return _vm.$emit('interact', event, type, initial);
        },
        "click": function () {
          return _vm.$emit('event:click', event);
        }
      }
    });
  }), _c('div', {
    staticClass: "dimmer"
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }