<template>
  <div
    class="vs-scheduler"
    :style="{ '--cols': columns.length }"
  >

    <vs-loader
      v-if="isLoading"
      style="top: 0"
      full
      text="Pobieranie informacji o wydarzeniach..."
    />

    <div class="column-header">
      <vs-button
        vs-variant="link"
        @click="() => moveDays(-7)"
      >
        Poprzedni tydzień
      </vs-button>
      <div class="scheduler-details">
        <div class="mode" @click="$emit('scope:toggle')">
          {{ columnScopeDays === 1 ? 'Widok dzienny' : 'Widok tygodniowy' }}
          <feather-icon icon="ChevronDownIcon" size="16" />
        </div>
        <div class="scope">
          {{ formatDate.scheduleWeek(columns[0])}} -
          {{ formatDate.scheduleWeek(
            addDays(columns[columns.length - 1], columnScopeDays === 1 ? 0 : columnScopeDays)
          )}}
        </div>
      </div>
      <vs-button
        vs-variant="link"
        @click="() => moveDays(7)"
      >
        Następny tydzień
      </vs-button>
    </div>

    <div
      class="day-labels"
      :style="{
        '--column-width': `${columnWidth}px`,
        '--event-opacity': `${isInteracting ? '0.3' : '1'}`,
        ...interactionParamsStyle,
      }"
      ref="labelsRef"
    >

      <div class="selection-indicator"></div>
      <div class="field header"></div>

      <div
        class="field header"
        v-for="(column, idx) in columns"
        :key="`column-${idx}`"
      >
        <template v-if="columnScopeDays === 1">
          {{ getDayName(column) }}
          {{ formatDate.scheduleWeek(column) }}
        </template>
        <template v-else>
          {{ formatDate.scheduleWeek(column) }} -
          {{ formatDate.scheduleWeek(addDays(column, columnScopeDays))}}
        </template>
      </div>

      <vs-scheduler-event-reel
        v-for="(vehicle) in events"
        :key="`event-reel-${vehicle.vehicle.id}`"
        :events="vehicle.events"
        :scheduleStartDate="scheduleStartDate"
        :label="vehicle.vehicle.name"
        :daysPerColumn="columnScopeDays"
        @resize="(width, event) => resizeEvent(width, vehicle, event)"
        @move="(offset, event) => moveEvent(offset, vehicle, event)"
        @interact="(event, type, initial) => startInteraction(event, type, initial)"
        @add="() => $emit('event:create', vehicle.vehicle)"
        @event:click="(event) => $emit('event:click', { ...event, vehicle: vehicle.vehicle })"
      >
        <template #event-source>
          <base-resource-label
            :resource="vehicle.vehicle"
            target="_blank"
          />
        </template>
      </vs-scheduler-event-reel>

    </div>

    <br>
    <br>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import formatDate, { addDays } from '@/libs/date-formatter';
import useMousePosition from '@/hooks/useMousePosition';
import BaseResourceLabel from '@/components/views/base/resource/BaseResourceLabel.vue';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import VsButton from '../vs-button/VsButton.vue';
import VsSchedulerEventReel from './VsSchedulerEventReel.vue';
import VsLoader from '../vs-loader/VsLoader.vue';

export default {
  name: 'VsScheduler',
  emits: [
    'event:create',
    'event:update',
    'event:click',
    'schedule:change-scope',
  ],
  props: {
    events: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    columnScopeDays: {
      type: Number,
      default: 1,
    },
  },
  setup(props, { emit }) {
    const columns = ref([]);

    const labelsRef = ref(null);
    const columnScopeDays = computed(() => props.columnScopeDays); // days per column
    const columnWidth = ref(100);

    const updateColumnWidth = () => {
      const width = labelsRef.value?.clientWidth ?? 1500;
      columnWidth.value = ((width - 300) / (columns.value.length)) / columnScopeDays.value;
    };

    const generateWeek = (startDate) => {
      const arr = [];

      for (let i = 0; i < 7 * columnScopeDays.value; i += 1 * columnScopeDays.value) {
        arr.push(addDays(startDate, i));
      }

      columns.value = arr;
      updateColumnWidth();
    };

    generateWeek(Date.now());

    const moveDays = (count = 7) => {
      const nextWeekStart = addDays(columns.value?.[0], count * columnScopeDays.value);
      generateWeek(nextWeekStart);

      emit('schedule:change-scope', nextWeekStart, addDays(nextWeekStart, 6 * columnScopeDays.value));
    };

    watch(columnScopeDays, () => {
      updateColumnWidth();
      moveDays(0);
    });

    const scheduleStartDate = computed(() => new Date(columns.value[0] ?? null));
    const isInteracting = ref(false);

    const addDay = () => {
      const date = addDays(columns.value[columns.value.length - 1], 1);
      columns.value.push(date);
      updateColumnWidth();
    };

    const getDateOffsetColumn = (date = null) => {
      const d = new Date(date);
      const diff = d.getTime() - scheduleStartDate.value.getTime();
      const offset = Math.ceil(diff / (1000 * 3600 * 24));
      return offset;
    };

    const getDateWidthColumn = (start = null, end = null) => {
      const d = new Date(start);
      const e = new Date(end);
      const diff = e.getTime() - d.getTime();
      const offset = Math.ceil(diff / (1000 * 3600 * 24)) + 1;
      return offset;
    };

    watch(() => labelsRef.value, () => {
      const resizeObserver = new ResizeObserver(() => updateColumnWidth());
      resizeObserver.observe(labelsRef.value);
      updateColumnWidth();
    });

    const resizeEvent = (width, vehicle, event) => {
      isInteracting.value = false;
      if (!event) return;

      const diff = width / columnWidth.value;

      emit('event:update', {
        ...event,
        vehicle: vehicle.vehicle,
        date_to: addDays(event.date_to, diff),
        days: Math.max(event.days + Math.round(diff), 1),
      });
    };

    const moveEvent = (offset, vehicle, event) => {
      isInteracting.value = false;
      if (!event) return;

      const diff = offset / columnWidth.value;

      const oldFrom = addDays(event.date_from, 0);
      const oldTo = addDays(event.date_to, 0);
      const newFrom = addDays(event.date_from, diff);
      const newTo = addDays(event.date_to, diff);

      if (newFrom === oldFrom && newTo === oldTo) return;

      emit('event:update', {
        ...event,
        vehicle: vehicle.vehicle,
        date_from: addDays(event.date_from, diff),
        date_to: addDays(event.date_to, diff),
      });
    };

    const interactionParams = ref({
      resizing: false,
      initialMouseX: 0,
      initialDateStart: 0,
      initialDateEnd: 0,
      offset: 0,
      initialWidth: 1,
      width: 1,
    });

    const startInteraction = (event, type, initialMouseX) => {
      const offset = getDateOffsetColumn(event.date_from);
      const width = getDateWidthColumn(event.date_from, event.date_to);

      interactionParams.value = {
        resizing: type === 'resize',
        initialMouseX,
        initialDateStart: event.date_from,
        initialDateEnd: event.date_to,
        initialOffset: offset,
        offset,
        initialWidth: width,
        width,
      };
      isInteracting.value = true;
    };

    watch(useMousePosition().x, (val) => {
      if (!isInteracting.value) return;
      const {
        initialWidth, initialOffset, initialMouseX, resizing,
      } = interactionParams.value;

      const diff = val - initialMouseX;
      const translatedDiff = Math.round(diff / columnWidth.value);

      interactionParams.value = {
        ...interactionParams.value,
        width: resizing ? initialWidth + translatedDiff : initialWidth,
        offset: !resizing ? initialOffset + translatedDiff : initialOffset,
      };
    });

    const interactionParamsStyle = computed(() => {
      const { offset, width } = interactionParams.value;
      const outOfBoundaries = Math.max(offset, 0) === 0;
      return {
        '--interaction-opacity': isInteracting.value ? 1 : 0,
        '--interaction-offset': Math.max(offset, 0) * columnWidth.value,
        '--interaction-width': outOfBoundaries
          ? Math.max((width + offset) * columnWidth.value, 0)
          : width * columnWidth.value,
      };
    });

    const updateVehicles = () => {
      useInvestmentsApi()
        .updateManyResources({
          investment_base_resources: [
            {
              id: 1,
              base_vehicle_id: 1,
              days_number: 1,
              investment_id: 1,
              resource_data: {
                scheduler: {
                  start_date: '2022-04-20',
                  end_date: '2022-04-21',
                },
              },
            },
          ],
        });
    };

    const getDayName = (day) => {
      const date = new Date(day);
      return date.toLocaleDateString('pl-PL', { weekday: 'short' });
    };

    return {
      columnWidth,
      labelsRef,
      getDateOffsetColumn,
      getDateWidthColumn,
      resizeEvent,
      isInteracting,
      moveEvent,
      columns,
      addDay,
      moveDays,
      formatDate,
      startInteraction,
      interactionParamsStyle,
      scheduleStartDate,
      updateVehicles,
      getDayName,
      addDays,
    };
  },
  components: {
    VsButton,
    VsSchedulerEventReel,
    BaseResourceLabel,
    VsLoader,
  },
};
</script>

<style lang="sass" scoped>
.selection-indicator
  position: absolute
  width: calc(var(--interaction-width, 100) * 1px)
  left: calc(var(--interaction-offset, 0) * 1px + 300px)
  height: 100%
  background: var(--vs-scheduler-highlight)
  transition: 0.4s all ease
  opacity: var(--interaction-opacity)
  border-bottom: 1px solid var(--vs-primary)
  border-top: 1px solid var(--vs-primary)
  pointer-events: none

.scheduler-details
  display: flex
  flex-direction: column
  align-items: center

  .mode
    font-size: 14pt
    font-weight: 500
    cursor: pointer
    transition: 0.2s opacity ease-in-out

    &:hover
      opacity: 0.7

.vs-scheduler
  --cols: 7
  background: var(--vs-scheduler-bg)
  border-radius: 10px
  overflow: hidden

  .column-header
    display: flex
    justify-content: space-between
    padding: 20px 0

  .day-labels
    --interaction-width: 100

    display: grid
    grid-template-columns: 300px repeat(var(--cols), 1fr)
    position: relative

  .field
    border-bottom: 1px solid var(--vs-scheduler-border)
    position: relative
    z-index: 3

    &.header
      padding: 20px
      display: flex
      justify-content: center
      align-items: center

      &:not(:last-child)
        border-right: 1px solid var(--vs-scheduler-border)
</style>
