var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "vs-scheduler",
    style: {
      '--cols': _vm.columns.length
    }
  }, [_vm.isLoading ? _c('vs-loader', {
    staticStyle: {
      "top": "0"
    },
    attrs: {
      "full": "",
      "text": "Pobieranie informacji o wydarzeniach..."
    }
  }) : _vm._e(), _c('div', {
    staticClass: "column-header"
  }, [_c('vs-button', {
    attrs: {
      "vs-variant": "link"
    },
    on: {
      "click": function () {
        return _vm.moveDays(-7);
      }
    }
  }, [_vm._v(" Poprzedni tydzień ")]), _c('div', {
    staticClass: "scheduler-details"
  }, [_c('div', {
    staticClass: "mode",
    on: {
      "click": function ($event) {
        return _vm.$emit('scope:toggle');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.columnScopeDays === 1 ? 'Widok dzienny' : 'Widok tygodniowy') + " "), _c('feather-icon', {
    attrs: {
      "icon": "ChevronDownIcon",
      "size": "16"
    }
  })], 1), _c('div', {
    staticClass: "scope"
  }, [_vm._v(" " + _vm._s(_vm.formatDate.scheduleWeek(_vm.columns[0])) + " - " + _vm._s(_vm.formatDate.scheduleWeek(_vm.addDays(_vm.columns[_vm.columns.length - 1], _vm.columnScopeDays === 1 ? 0 : _vm.columnScopeDays))) + " ")])]), _c('vs-button', {
    attrs: {
      "vs-variant": "link"
    },
    on: {
      "click": function () {
        return _vm.moveDays(7);
      }
    }
  }, [_vm._v(" Następny tydzień ")])], 1), _c('div', {
    ref: "labelsRef",
    staticClass: "day-labels",
    style: Object.assign({
      '--column-width': `${_vm.columnWidth}px`,
      '--event-opacity': `${_vm.isInteracting ? '0.3' : '1'}`
    }, _vm.interactionParamsStyle)
  }, [_c('div', {
    staticClass: "selection-indicator"
  }), _c('div', {
    staticClass: "field header"
  }), _vm._l(_vm.columns, function (column, idx) {
    return _c('div', {
      key: `column-${idx}`,
      staticClass: "field header"
    }, [_vm.columnScopeDays === 1 ? [_vm._v(" " + _vm._s(_vm.getDayName(column)) + " " + _vm._s(_vm.formatDate.scheduleWeek(column)) + " ")] : [_vm._v(" " + _vm._s(_vm.formatDate.scheduleWeek(column)) + " - " + _vm._s(_vm.formatDate.scheduleWeek(_vm.addDays(column, _vm.columnScopeDays))) + " ")]], 2);
  }), _vm._l(_vm.events, function (vehicle) {
    return _c('vs-scheduler-event-reel', {
      key: `event-reel-${vehicle.vehicle.id}`,
      attrs: {
        "events": vehicle.events,
        "scheduleStartDate": _vm.scheduleStartDate,
        "label": vehicle.vehicle.name,
        "daysPerColumn": _vm.columnScopeDays
      },
      on: {
        "resize": function (width, event) {
          return _vm.resizeEvent(width, vehicle, event);
        },
        "move": function (offset, event) {
          return _vm.moveEvent(offset, vehicle, event);
        },
        "interact": function (event, type, initial) {
          return _vm.startInteraction(event, type, initial);
        },
        "add": function () {
          return _vm.$emit('event:create', vehicle.vehicle);
        },
        "event:click": function (event) {
          return _vm.$emit('event:click', Object.assign({}, event, {
            vehicle: vehicle.vehicle
          }));
        }
      },
      scopedSlots: _vm._u([{
        key: "event-source",
        fn: function () {
          return [_c('base-resource-label', {
            attrs: {
              "resource": vehicle.vehicle,
              "target": "_blank"
            }
          })];
        },
        proxy: true
      }], null, true)
    });
  })], 2), _c('br'), _c('br')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }