<template>
  <div
    :class="`vs-scheduler-event ${isInteracting && 'interacting'}`"
    :style="eventStyles"
    @mousedown="startMoving"
  >

    <div class="event-background" />

    <div class="header">
      <div class="name">
        {{ name }}
        <div class="color-indicator">
          {{ category }}
        </div>
        <template v-if="overwritten">
          ZMIENIONO
        </template>
      </div>

      <div class="date">
        {{ days }} dni
        ({{ formatDate.training(dateFrom) }}
        -
        {{ formatDate.training(dateTo) }})
      </div>

    </div>

    <div
      class="resize-indicator"
      @mousedown.stop="startResizing"
      v-if="!disableInteraction"
    >
      ||
    </div>

  </div>
</template>

<script>
import formatDate from '@/libs/date-formatter';
import { computed, ref } from 'vue';
import useMousePosition from '@/hooks/useMousePosition';
import getContrasting from '@/libs/contrast';

export default {
  name: 'VsSchedulerEvent',
  props: {
    name: String,
    span: {
      type: Number,
      default: 1,
    },
    offset: {
      type: Number,
      default: 0,
    },
    row: {
      type: Number,
      default: 0,
    },
    dateFrom: {
      type: String,
    },
    dateTo: {
      type: String,
    },
    days: {
      type: Number || String,
    },
    overwritten: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
    },
    category: {
      type: String,
    },
    disableInteraction: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { subscribe, x } = useMousePosition();
    const resizedWidth = ref(0);
    const isResizing = ref(false);
    const isMoving = ref(false);
    const isInteracting = computed(() => isResizing.value || isMoving.value);

    const resizedWidthDiff = computed(() => (((isResizing.value || isMoving.value)
      && !props.disableInteraction)
      ? x.value - resizedWidth.value
      : 0));

    const eventStyles = computed(() => ({
      '--event-span': props.span,
      '--event-offset': props.offset,
      '--event-resized': isResizing.value ? resizedWidthDiff.value : 0,
      '--event-moved': isMoving.value ? resizedWidthDiff.value : 0,
      '--event-transition-delay': isInteracting.value ? '0s' : '0.2s',
      '--event-row': props.row,
      '--event-color': props.color,
      '--event-color-contrast': getContrasting(props.color),
      'z-index': isInteracting.value ? 4 : 1,
    }));

    const startResizing = (e) => {
      const initialMouseX = e.clientX;
      resizedWidth.value = initialMouseX;
      isResizing.value = true;

      if (props.disableInteraction) return;
      emit('interact', { type: 'resize', initial: initialMouseX });
    };

    const startedMovingAt = ref(null);
    const maxClickDelay = 200;
    const startMoving = (e) => {
      const initialMouseX = e.clientX;
      resizedWidth.value = initialMouseX;
      isMoving.value = true;
      startedMovingAt.value = Date.now();

      if (props.disableInteraction) return;
      emit('interact', { type: 'move', initial: initialMouseX });
    };

    subscribe((type) => {
      if (type !== 'mouseup' || (!isResizing.value && !isMoving.value)) return;
      const shortClicked = Date.now() - startedMovingAt.value <= maxClickDelay;

      if (shortClicked) {
        emit('click');
      }

      if (props.disableInteraction) return;
      emit(isResizing.value ? 'resize' : 'move',
        shortClicked ? 0 : resizedWidthDiff.value);

      isResizing.value = false;
      isMoving.value = false;
    });

    return {
      eventStyles,
      startResizing,
      isResizing,
      startMoving,
      isMoving,
      resizedWidthDiff,
      isInteracting,
      formatDate,
      getContrasting,
    };
  },
};
</script>

<style lang="sass" scoped>
.vs-scheduler-event
  --event-span: 1
  --event-offset: 0
  --event-resized: 0
  --event-moved: 0
  --event-transition-delay: 0s
  --event-row: 0
  --event-color: var(--vs-scheduler-event-bg)
  --event-color-contrast: white

  min-width: var(--column-width)
  border-radius: 4px
  height: 30px
  left: calc((var(--column-width) * var(--event-offset) + 300px) + var(--event-moved) * 1px)
  top: calc(30px * var(--event-row) + 5px * var(--event-row))
  position: absolute
  display: flex
  align-items: center
  padding: 0 5px
  margin: 5px 2px
  user-select: none
  font-weight: 500
  cursor: pointer
  width: calc(var(--column-width) * var(--event-span) - 5px + var(--event-resized) * 1px)
  transition: var(--event-transition-delay) all ease-in-out
  opacity: var(--event-opacity, 1)
  border: 1px solid var(--vs-scheduler-event-border)

  &:hover
    z-index: 4 !important

    .date
      opacity: 1

    .name
      opacity: 0

    .resize-indicator
      opacity: 1
      pointer-events: auto

  &.interacting
    opacity: 1

  .event-background
    border-radius: 4px
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: var(--event-color)
    opacity: 0.2

  .header
    position: sticky
    left: 300px
    display: flex
    align-items: center
    padding-left: 5px
    mix-blend-mode: overlay

  .name
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
    margin-right: 10px
    display: flex
    align-items: center
    transition: 0.2s all ease-in-out
    gap: 10px

  .date
    top: 0
    left: 0
    min-width: 100%
    position: absolute
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
    transition: 0.2s all ease-in-out
    opacity: 0

  .color-indicator
    border-radius: 5px
    padding: 0 10px
    font-size: 9pt
    text-transform: uppercase
    font-weight: 700
    background: var(--event-color)
    color: var(--event-color-contrast)

  .resize-indicator
    cursor: col-resize
    position: absolute
    top: 0
    right: 0
    display: block
    width: 20px
    height: 100%
    background: rgba(0, 0, 0, 0.1)
    color: rgba(128, 128, 128, 0.3)
    display: flex
    align-items: center
    justify-content: center
    opacity: 0
    pointer-events: none
    transition: 0.2s opacity ease-in-out
</style>
